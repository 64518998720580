.fui-FluentProviderr1 {
    --fontFamilyBase: Gilroy-Regular,Arial,Helvetica,Sans-serif !important;
}
.fnggedw {
    background-color: #0f1c44 !important;
}
.ffp7eso
{
    background-color: #0f1c44 !important;
}

.docViewer {
    flex: 1 0 61%;
    display: flex;
    flex-direction: row;
    flex-basis: 40%;
    background-color: red;
    margin-left: 20px;
}

.react-pdf__Document{
    margin-left: 20px;
}


.fui-Avatar__initials
{
    width: 31px !important;
    height: 31px !important;
    background-color: #0c6818 !important;
    color: #ffffff !important;
}

.fui-Subtitle1
{
    color: #00cc87 !important;
}

#react-doc-viewer{
    background-color:#f5f5f5 !important;
}

#pdf-controls{
    background-color:#f5f5f5 !important;
}
